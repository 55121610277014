<template>
  <div class="cost">
    <Header
      back="返回"
      title="研发项目"
      index="首页"
      titleOne="科创模块"
      titleTwo="研发项目"
      beforeTitle="研发项目计划书"
    />
    <div class="content">
      <h1>研发项目计划书</h1>
      <el-form
        :rules="formRule"
        ref="formRule"
        :model="form"
        :label-position="labelPosition"
        label-width="80px"
      >
        <el-form-item label="项目名称" prop="rd_name">
          <el-input v-model="form.rd_name" placeholder="请输入项目名称"></el-input>
        </el-form-item>
        <el-form-item label="立项部门" prop="organization">
          <el-input v-model="form.organization" placeholder="请输入立项部门"></el-input>
        </el-form-item>
        <el-form-item label="项目类别" prop="project_type">
          <el-input v-model="form.project_type" placeholder="请输入项目类别"></el-input>
        </el-form-item>
        <div class="clear"></div>
        <el-form-item label="研发开始时间" prop="start_time">
          <el-date-picker
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
            v-model="form.start_time"
            style="width: 100%"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="研发结束时间" prop="end_time">
          <el-date-picker
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
            v-model="form.end_time"
            style="width: 100%"
          ></el-date-picker>
        </el-form-item>
        <div class="clear"></div>
        <el-form-item label="项目负责人" prop="charge_person">
          <el-input v-model="form.charge_person" placeholder="请输入项目负责人"></el-input>
        </el-form-item>
        <el-form-item label="预计开发开始时间" prop="plan_start_time">
          <el-date-picker
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
            v-model="form.plan_start_time"
            style="width: 100%"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="预计开发结束时间" prop="plan_end_time">
          <el-date-picker
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
            v-model="form.plan_end_time"
            style="width: 100%"
          ></el-date-picker>
        </el-form-item>
        <div class="clear"></div>
        <el-form-item label="立项目的" style="width: 100%">
          <el-input type="textarea" :rows="5" placeholder="请输入内容" v-model="form.objective"></el-input>
        </el-form-item>
        <el-form-item label="立项意义" style="width: 100%">
          <el-input type="textarea" :rows="5" placeholder="请输入内容" v-model="form.meaning"></el-input>
        </el-form-item>
        <el-form-item label="项目创新关键技术" style="width: 100%">
          <el-input type="textarea" :rows="5" placeholder="请输入内容" v-model="form.main_technology"></el-input>
        </el-form-item>
        <el-form-item label="项目创新之处/立项创新点" style="width: 100%">
          <el-input type="textarea" :rows="5" placeholder="请输入内容" v-model="form.innovate"></el-input>
        </el-form-item>
        <el-form-item label="实施方案" style="width: 100%">
          <el-input type="textarea" :rows="5" placeholder="请输入内容" v-model="form.implementation"></el-input>
        </el-form-item>
        <el-form-item label="主要参与人员名单" style="width: 100%">
          <el-input v-model="form.participants" placeholder="请输入主要参与人员名单"></el-input>
        </el-form-item>
        <el-form-item label="项目预算（万元）" style="width: 100%">
          <el-input v-model="form.budget" placeholder="请输入项目预算"></el-input>
        </el-form-item>
        <el-form-item label="审批意见" style="width: 100%">
          <el-input type="textarea" :rows="5" placeholder="请输入内容" v-model="form.comments"></el-input>
        </el-form-item>
        <el-form-item label="审核人">
          <el-input v-model="form.reviewer" placeholder="请输入审核人"></el-input>
        </el-form-item>
      </el-form>
      <div class="foot_TJ">
        <el-button type="primary" :loading="btnLoading" @click="onSubmit" :disabled="isDisable">确定</el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      btnLoading: false,//提交按钮动画
      isDisable: false,
      labelPosition: "top",
      form: {
        rd_name: "",
        organization: "",
        project_type: "",
        start_time: "",
        end_time: "",
        plan_start_time: "",
        plan_end_time: "",
        objective: "",
        meaning: "",
        main_technology: "",
        innovate: "",
        implementation: "",
        participants: "",
        budget: "",
        comments: "",
        reviewer: "",
        id: "",
      },
      formRule: {
        rd_name: [
          {
            required: true,
            message: "请输入项目名称",
            trigger: "blur",
          },
        ],
        organization: [
          {
            required: true,
            message: "请输入立项部门",
            trigger: "blur",
          },
        ],
        project_type: [
          {
            required: true,
            message: "请输入项目类别",
            trigger: "blur",
          },
        ],
        start_time: [
          {
            required: true,
            message: "请选择研发开始时间",
            trigger: "change",
          },
        ],
        end_time: [
          {
            required: true,
            message: "请选择研发结束时间",
            trigger: "change",
          },
        ],
        charge_person: [
          {
            required: true,
            message: "请输入项目负责人",
            trigger: "blur",
          },
        ],
        plan_start_time: [
          {
            required: true,
            message: "请选择预计开发开始时间",
            trigger: "change",
          },
        ],
        plan_end_time: [
          {
            required: true,
            message: "请选择预计开发结束时间",
            trigger: "change",
          },
        ],
      },
    };
  },
  computed: {
    meber_id () {
      return this.$route.query.id;
    },
  },
  mounted () {
    if (this.meber_id) {
      this.axios
        .get("/api/rd/view_plan", {
          params: {
            rd_id: this.meber_id,
          },
        })
        .then((res) => {
          this.form = res.data;
        });
    }
  },
  methods: {
    onSubmit () {
      this.$refs.formRule.validate((vaild) => {
        this.form.rd_id = this.meber_id;
        if (vaild) {
          this.btnLoading = true
          this.axios
            .post("/api/rd/store_plan", this.form)
            .then((res) => {
              this.$message({
                type: "success",
                message: "保存成功!",
              });
              this.$router.push("/rd/index");
              this.isDisable = true;
              this.btnLoading = false
            })
            .catch((res) => {
              console.log("err:", res);
              this.btnLoading = false
            });
        } else {
          this.$message.error("请输入必填项");
        }
      });
    },
  },
};
</script>

<style scoped="scoped">
::v-deep.el-form-item {
  width: 23%;
  display: inline-block;
  margin-right: 2%;
}

.el-select {
  width: 100%;
}

.ImportTop span {
  color: #a6abc7;
}
</style>
